
import router from '@/router';
import { MenuItem } from 'primevue/menuitem';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    titleBold: {
      type: String,
    },
    headingIcon: {
      type: String,
    },
    breadcrumb: {
      type: Array as () => MenuItem[],
    },
    description: {
      type: String,
    },
    details: {
      type: Array as () => any[],
    },
    tabs: {
      type: Object as () => MenuItem[],
    },
    subHeading: {
      type: Boolean,
      default: () => false,
    },
    subHeadingLevel: {
      type: Number,
      default: () => 2,
    },
    divider: {
      type: Boolean,
      default: () => true,
    },
    tag: {
      type: String,
      default: () => '',
    },
    tagStyle: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const containerSize = computed(() => {
      return props.tabs ? 'container-big' : 'container';
    });
    const breadcrumbClicked = (event: any, item: any) => {
      event.preventDefault();
      if (item.disabled) {
        return;
      }
      router.push(item.to);
    };

    const tagClass = computed(() => {
      switch (props.tagStyle) {
        case 'warning':
          return 'heading-tag--warning';
        case 'success':
          return 'heading-tag--success';
        case 'error':
          return 'heading-tag--error';
        case 'info':
          return 'heading-tag--info';
        default:
          return '';
      }
    });

    const detailClicked = (event: any, item: any) => {
      event.preventDefault();
      if (item.command) {
        item.command({
          originalEvent: event,
          item: item,
        });
      }
    };
    return { containerSize, tagClass, breadcrumbClicked, detailClicked };
  },
});
