
import { computed, defineComponent, onMounted, PropType, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import CardComponent from '@/components/CardComponent.vue';
import Account, { AccountFormDto, updateAccountFromAccountFormDto } from '@/models/Account';
import { accountBasicInformationForm, isAccountBasicInformationFormValid } from '@/validation/accountForm';
import inputHelper from '@/helpers/Input.helper';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import AccountImageCard from './AccountImageCard.vue';
import { accountService } from '@/services/AccountService';
import { accountDocumentService } from '@/services/AccountDocumentService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { Document } from '@/models/AccountDocument';
import { useProfileStore } from '@/store/profile.module';

export default defineComponent({
  components: { CardComponent, HealzInput, AccountImageCard },
  emits: ['update-account-prop'],
  props: {
    account: {
      type: Object as PropType<Account>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t, availableLocales } = useI18n();
    const mutableAccount: Ref<AccountFormDto> = ref(new AccountFormDto(props.account));
    const languageOptions = ref(inputHelper.getLangOptions(availableLocales));
    const valid = computed(() => isAccountBasicInformationFormValid(validatedForm.value));
    const validatedForm = computed(() => accountBasicInformationForm(mutableAccount.value));
    const accountBackup = ref(mutableAccount.value);
    const editMode = ref(false);
    const submiting = ref(false);
    const toast = useToast();
    const accountImgUrl = ref('');
    const imageCardRef = ref();
    const profileStore = useProfileStore();

    onMounted(async () => {
      if (props.account.profileImage) {
        accountImgUrl.value = await getFileContentUrlFromS3(props.account.id, props.account.profileImage);
      }
    });

    const onEditForm = () => {
      mutableAccount.value = new AccountFormDto(props.account);
      accountBackup.value = inputHelper.createCopy<AccountFormDto>(mutableAccount.value);
      editMode.value = true;
    };

    const onSubmit = async () => {
      if (!valid.value) {
        return;
      }
      await modifyAccount(updateAccountFromAccountFormDto(props.account, mutableAccount.value));
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      mutableAccount.value = inputHelper.createCopy<AccountFormDto>(accountBackup.value);
    };

    async function modifyAccount(account: Account) {
      submiting.value = true;
      const result = await accountService.update(props.account.id, account);
      await imageCardRef.value?.uploadFiles().then(() => {
        profileStore.fetchUserAccounts();
      });
      emit('update-account-prop');
      submiting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditAccount')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditAccount')}`, life: 3000 });
        editMode.value = false;
      }
    }

    const getFileContentUrlFromS3 = async (accountId: string, document: Document) => {
      const response = await accountDocumentService.getContentURLFromS3(
        accountId,
        document.entityType,
        document.entityId,
        document.id,
      );
      if (!(response instanceof ServiceError)) {
        return response.url;
      }
      return '';
    };

    return {
      mutableAccount,
      accountImgUrl,
      imageCardRef,
      languageOptions,
      validatedForm,
      editMode,
      submiting,
      onEditForm,
      onSubmit,
      onCancelEdition,
      getFileContentUrlFromS3,
    };
  },
});
