import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "l-row--valign" }
const _hoisted_3 = { class: "title-container" }
const _hoisted_4 = { class: "bold-text" }
const _hoisted_5 = { class: "bold-text" }
const _hoisted_6 = { class: "bold-text" }
const _hoisted_7 = {
  key: 3,
  class: "description"
}
const _hoisted_8 = { key: 4 }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = {
  key: 1,
  class: "p-col-12 tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabMenu = _resolveComponent("TabMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.containerSize,
      { subheading: _ctx.subHeading },
      { 'no-divider': _ctx.subHeading && !_ctx.divider },
      { 'l-heading-container heading': !_ctx.subHeading },
    ])
  }, [
    (_ctx.breadcrumb)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Breadcrumb, { model: _ctx.breadcrumb }, {
            item: _withCtx(({ item }) => [
              (item.to)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: item.to,
                    class: _normalizeClass({ 'p-disabled': item.disabled }),
                    onClick: ($event: any) => (_ctx.breadcrumbClicked($event, item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "class", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "left", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.subHeading && _ctx.subHeadingLevel == 3)
          ? (_openBlock(), _createElementBlock("h3", {
              key: 0,
              class: _normalizeClass({ h4: _ctx.headingIcon })
            }, [
              (_ctx.headingIcon)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(_ctx.headingIcon),
                    "aria-hidden": "true"
                  }, null, 2))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
              _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.titleBold), 1)
            ], 2))
          : (_ctx.subHeading && _ctx.subHeadingLevel == 2)
            ? (_openBlock(), _createElementBlock("h2", {
                key: 1,
                class: _normalizeClass({ h3: _ctx.headingIcon })
              }, [
                (_ctx.headingIcon)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(_ctx.headingIcon),
                      "aria-hidden": "true"
                    }, null, 2))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
                _createElementVNode("strong", _hoisted_5, _toDisplayString(_ctx.titleBold), 1)
              ], 2))
            : (_openBlock(), _createElementBlock("h1", {
                key: 2,
                class: _normalizeClass([{ h2: _ctx.headingIcon }, "heading-title"])
              }, [
                (_ctx.headingIcon)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(_ctx.headingIcon),
                      "aria-hidden": "true"
                    }, null, 2))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
                (_ctx.tag)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["p-tag heading-tag", _ctx.tagClass])
                    }, _toDisplayString(_ctx.tag), 3))
                  : _createCommentVNode("", true),
                _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.titleBold), 1)
              ], 2)),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true),
        (_ctx.details)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  (!item.clicable)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (item.icon)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass(item.icon),
                              "aria-label": item.iconLabel
                            }, null, 10, _hoisted_9))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", null, _toDisplayString(item.label), 1)
                      ], 64))
                    : (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        class: "p-button-link",
                        onClick: ($event: any) => (_ctx.detailClicked($event, item))
                      }, {
                        default: _withCtx(() => [
                          (item.icon)
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: _normalizeClass(item.icon),
                                "aria-hidden": "true"
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['actions', { 'fixed-actions': _ctx.subHeading }])
      }, [
        _renderSlot(_ctx.$slots, "right", {}, undefined, true)
      ], 2)
    ]),
    (_ctx.tabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_TabMenu, { model: _ctx.tabs }, null, 8, ["model"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}