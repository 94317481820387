
import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { ServiceError } from '@/services/util/ServiceError';
import { accountService } from '@/services/AccountService';
import AccountBasicInformationCard from '@/components/account/AccountBasicInformationCard.vue';
import AccountTaxDataCard from '@/components/account/AccountTaxDataCard.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import Account from '@/models/Account';
import { useProfileStore } from '@/store/profile.module';
import i18n from '@/i18n';

export default defineComponent({
  components: { AccountBasicInformationCard, AccountTaxDataCard, HeadingComponent, LoadingComponent },
  setup() {
    const storeProfile = useProfileStore();
    const route = useRoute();
    const isoCountries = require('i18n-iso-countries');
    const account: Ref<Account | undefined> = ref();
    const countriesList = computed(() => {
      let officialList = isoCountries.getNames(i18n.global.locale.value, { select: 'official' });
      return Object.keys(officialList)
        .map((key) => ({
          value: key,
          label: officialList[key],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    });

    onMounted(async () => {
      registerCountries();
      await getAccountInfo();
    });

    function registerCountries() {
      if (i18n.global.locale.value == 'es') {
        isoCountries.registerLocale(require('i18n-iso-countries/langs/es.json'));
      } else {
        isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
      }
    }

    async function getAccountInfo() {
      account.value = undefined;
      const result = await accountService.find(storeProfile.accountId);
      if (!(result instanceof ServiceError)) {
        account.value = result;
      }
    }

    return { account, route, countriesList, getAccountInfo };
  },
});
