import { Document } from './AccountDocument';
export default interface Account {
  address: string;
  businessName: string;
  city: string;
  country: string;
  creationDate: string;
  deleted: boolean;
  email: string;
  id: string;
  language: string;
  modificationDate: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  state: string;
  taxNumber: string;
  timezone: string;
  userName: string;
  userSurname: string;
  profileImage: Document | null;
}

class AccountFormDto {
  name: string;
  language: string;
  timezone: string;
  businessName: string;
  taxNumber: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  state: string;
  profileImage: Document | null;
  constructor(account: Account) {
    this.name = account.name;
    this.language = account.language;
    this.timezone = account.timezone;
    this.businessName = account.businessName;
    this.taxNumber = account.taxNumber;
    this.phoneNumber = account.phoneNumber;
    this.address = account.address;
    this.postalCode = account.postalCode;
    this.city = account.city;
    this.country = account.country;
    this.state = account.state;
    this.profileImage = account.profileImage ? account.profileImage : null;
  }
}

export function updateAccountFromAccountFormDto(account: Account, accountformDto: AccountFormDto): Account {
  const newAccount = Object.assign({}, account);
  newAccount.name = accountformDto.name;
  newAccount.language = accountformDto.language;
  newAccount.timezone = accountformDto.timezone;
  newAccount.businessName = accountformDto.businessName;
  newAccount.taxNumber = accountformDto.taxNumber;
  newAccount.phoneNumber = accountformDto.phoneNumber;
  newAccount.address = accountformDto.address;
  newAccount.postalCode = accountformDto.postalCode;
  newAccount.city = accountformDto.city;
  newAccount.country = accountformDto.country;
  newAccount.profileImage = accountformDto.profileImage;
  return newAccount;
}

export interface AccountSelector {
  idAccount: string;
  name: string;
  iconUrl: string;
}

export { Account, AccountFormDto };
