
import { defineComponent, computed } from 'vue';
import HeadingComponent from './HeadingComponent.vue';
export default defineComponent({
  components: { HeadingComponent },
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    cardHeadingLevel: {
      type: Number,
      default: () => 2,
    },
    editMode: {
      type: Boolean,
      default: () => false,
    },
    border: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(_props, { slots }) {
    const cardContentWasPassed = computed(() => {
      return 'cardContent' in slots;
    });

    const renderDefaultSlot = computed(() => {
      return !cardContentWasPassed.value;
    });

    if (cardContentWasPassed.value && 'default' in slots) {
      console.warn(
        'You can only have either a #cardContent or a default slot on a card component. When both are passed, only the #cardContent slot will be rendered.',
      );
    }
    return {
      slots,
      renderDefaultSlot,
      cardContentWasPassed,
    };
  },
});
