import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alt-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.editable && !_ctx.modelValue && _ctx.displayEmptyValueText)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.emptyValueText), 1))
    : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.inputType), _mergeProps({
        key: 1,
        id: _ctx.id,
        class: _ctx.editable ? '' : 'no-editable',
        useGrouping: _ctx.useGrouping,
        modelValue: _ctx.modelValue
      }, _ctx.propsToPassDown, {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event)))
      }), null, 16, ["id", "class", "useGrouping", "modelValue"]))
}