import { UserRole } from '@/models/User';
import ISO6391, { LanguageCode } from 'iso-639-1';

const inputHelper = {
  getLangOptions(locales: string[]): any[] {
    const options = [] as any[];
    locales.forEach((locale) => {
      options.push({ label: ISO6391.getNativeName(locale as LanguageCode), value: locale });
    });
    return options;
  },

  getUserTypeOptions(t: any) {
    return [
      { label: t(`roles.${UserRole.ADMIN}`), value: UserRole.ADMIN },
      { label: t(`roles.${UserRole.RECEPTIONIST}`), value: UserRole.RECEPTIONIST },
      { label: t(`roles.${UserRole.SPECIALIST}`), value: UserRole.SPECIALIST },
    ];
  },

  createCopy<Type>(objectToCopy: Type): Type {
    return JSON.parse(JSON.stringify(objectToCopy));
  },
};

export default inputHelper;
