
import { CSSProperties, ref } from 'vue';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  props: {
    spinnerColor: {
      type: String,
      default: () => {
        return 'white';
      },
    },
  },
  setup(props) {
    const spinnerStyles = ref<CSSProperties>({ '--spinner-color': `var(--${props.spinnerColor})` } as any);
    return { spinnerStyles };
  },
});
