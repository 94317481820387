import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "load-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "lds-spinner",
      style: _normalizeStyle(_ctx.spinnerStyles)
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1),
      _createElementVNode("div", null, null, -1)
    ]), 4)
  ]))
}