enum EntityType {
  PROFILE_IMAGE = 'PROFILE_IMAGE',
}

interface Document {
  contentType: string;
  creationDate?: string;
  deleted: boolean;
  entityId: string;
  entityType: EntityType;
  id: string;
  name: string;
  accountId: string;
  s3Id: string;
  size: number;
}

export { Document, EntityType };
