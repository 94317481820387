import { AccountFormDto } from '@/models/Account';
import { required, isObjectValid, ValidationResult } from './form';

export interface AccountBasicInformationFormValidity {
  name: ValidationResult;
  language: ValidationResult;
  timezone: ValidationResult;
}

export function isAccountBasicInformationFormValid(form: AccountBasicInformationFormValidity) {
  return isObjectValid<AccountBasicInformationFormValidity>(form);
}

export function accountBasicInformationForm(account: AccountFormDto): AccountBasicInformationFormValidity {
  const name = required(account.name);
  const language = required(account.language);
  const timezone = required(account.timezone);

  return {
    name,
    language,
    timezone,
  };
}
