
import { defineComponent, PropType, Ref, ref } from 'vue';
import Account, { AccountFormDto, updateAccountFromAccountFormDto } from '@/models/Account';
import CardComponent from '@/components/CardComponent.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';
import inputHelper from '@/helpers/Input.helper';
import { accountService } from '@/services/AccountService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { CardComponent, HealzInput },
  emits: ['update-account-prop'],
  props: {
    account: {
      type: Object as PropType<Account>,
      required: true,
    },
    countriesList: {
      type: Array as () => any[],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const mutableAccount: Ref<AccountFormDto> = ref(new AccountFormDto(props.account));
    const accountBackup = ref(mutableAccount.value);
    const editMode = ref(false);
    const submiting = ref(false);
    const toast = useToast();

    const onEditForm = () => {
      mutableAccount.value = new AccountFormDto(props.account);
      accountBackup.value = inputHelper.createCopy<AccountFormDto>(mutableAccount.value);
      editMode.value = true;
    };

    const onSubmit = async () => {
      await modifyAccount(updateAccountFromAccountFormDto(props.account, mutableAccount.value));
    };

    const onCancelEdition = async () => {
      editMode.value = false;
      mutableAccount.value = inputHelper.createCopy<AccountFormDto>(accountBackup.value);
    };

    async function modifyAccount(account: Account) {
      submiting.value = true;
      const result = await accountService.update(props.account.id, account);
      emit('update-account-prop');
      submiting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditAccount')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditAccount')}`, life: 3000 });
        editMode.value = false;
      }
    }

    return {
      mutableAccount,
      editMode,
      submiting,
      onEditForm,
      onSubmit,
      onCancelEdition,
    };
  },
});
