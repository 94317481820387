
type AvailableInputTipes = 'InputText' | 'InputNumber';

interface PropsToPassDown {
  useGrouping?: boolean;
  type?: string;
}
import { defineComponent, toRefs } from '@vue/runtime-core';

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    editable: {
      type: Boolean as () => boolean,
      default: true,
    },
    useGrouping: {
      type: Boolean as () => boolean,
      default: false,
    },
    id: {
      type: String as () => string,
      required: false,
    },
    inputType: {
      type: String as () => AvailableInputTipes,
      default: 'InputText',
    },
    modelValue: {
      type: String as () => string,
      required: true,
    },
    type: {
      type: String as () => string,
      default: '',
    },
    displayEmptyValueText: {
      type: Boolean as () => boolean,
      default: true,
    },
    emptyValueText: {
      type: String as () => string,
      default: '-',
    },
  },
  setup(props, { emit }) {
    const onInput = (event: any) => {
      emit('update:modelValue', event);
    };

    const propsToPassDown: PropsToPassDown = {};

    let { useGrouping, type } = toRefs(props);

    if (useGrouping.value) {
      propsToPassDown.useGrouping = useGrouping.value;
    }

    if (type.value) {
      propsToPassDown.type = type.value;
    }

    return { onInput, propsToPassDown };
  },
});
