import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "account-image-container" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 1,
  class: "document-item-container"
}
const _hoisted_4 = { class: "document-item__preview" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "document-item__info" }
const _hoisted_7 = { class: "file-name" }
const _hoisted_8 = { class: "file-size" }
const _hoisted_9 = { class: "document-item__actions" }
const _hoisted_10 = ["aria-label"]

import { ref, PropType, onMounted } from 'vue';
import { onAllTypeFileInputChange, isFileBeingCropped, FileData } from '@/helpers/composables/useFileInputCrop';
import { ServiceError } from '@/services/util/ServiceError';
import { accountDocumentService } from '@/services/AccountDocumentService';
import { Document, EntityType } from '@/models/AccountDocument';
import handleError from '@/services/util/ErrorHandler';

export default /*@__PURE__*/_defineComponent({
  __name: 'AccountImageCard',
  props: {
  editMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  accountImage: {
    type: Object as PropType<Document>,
    required: false,
  },
  urlImage: {
    type: String,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const loadFile = ref<FileData>();
const previewUrl = ref<string>();
const fileToDelete = ref<FileData>();
const fileInputRef = ref();

onMounted(async () => {
  if (!props.accountImage) {
    return;
  }

  await initFilesLoaded();
});

const initFilesLoaded = async () => {
  if (!props.accountImage) {
    return;
  }

  loadFile.value = undefined;

  if (props.urlImage) {
    loadFile.value = {
      id: props.accountImage.id,
      name: props.accountImage.name,
      url: props.urlImage,
      file: null,
    };
    previewUrl.value = props.urlImage;
  }
};

const openFileInput = () => {
  fileInputRef.value?.click();
};

const onFileSelect = async (event: any) => {
  const selectedFile = await onAllTypeFileInputChange(event).catch((error) => {
    handleError(error);
  });
  if (selectedFile) {
    previewUrl.value = selectedFile.previewUrl;
    if (selectedFile.uploadFile.file) {
      loadFile.value = {
        id: selectedFile.uploadFile.id,
        name: selectedFile.uploadFile.file.name,
        url: selectedFile.previewUrl,
        file: selectedFile.uploadFile.file,
      };
    }
  }
};

function deleteLoadedImage() {
  fileToDelete.value = loadFile.value;
  loadFile.value = undefined;
}

function bytesToMB(bytes: number): string {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2) + ' mb';
}

const generateS3Url = async (documentCreated: Document) => {
  const s3Url = await accountDocumentService.generateURLToUploadToS3(EntityType.PROFILE_IMAGE, documentCreated.id);

  if (s3Url instanceof ServiceError) {
    throw new Error('ERR_UPLOAD_FILE');
  }

  return s3Url;
};

const createNewDocument = (newFile: FileData) =>
  ({
    name: newFile.name,
    contentType: newFile.file?.type,
    size: loadFile.value?.file?.size,
  } as Document);

const createDocument = async (newDocument: Document) => {
  const documentCreated = await accountDocumentService.createDocument(EntityType.PROFILE_IMAGE, newDocument);

  if (documentCreated instanceof ServiceError) {
    throw new Error('ERR_UPLOAD_FILE');
  }

  return documentCreated;
};

const deleteFiles = async () => {
  if (fileToDelete.value && fileToDelete.value.id) {
    await accountDocumentService.deleteDocument(EntityType.PROFILE_IMAGE, fileToDelete.value.id);
  }
};

const uploadToS3 = async (s3Url: any, newFile: FileData) => {
  if (!newFile.file || !s3Url.url) {
    return;
  }
  const s3Document = await accountDocumentService.uploadToS3(s3Url.url, newFile.file);

  if (s3Document instanceof ServiceError) {
    throw new Error('ERR_UPLOAD_FILE');
  }
};

const uploadFiles = async () => {
  const fileToUpload = loadFile.value;

  const shouldUploadFile = (newFile: FileData) => !newFile.id && newFile.file;

  const processFile = async (newFile: FileData) => {
    const newDocument = createNewDocument(newFile);
    const documentCreated = await createDocument(newDocument);
    const s3Url = await generateS3Url(documentCreated);

    await uploadToS3(s3Url, newFile);
  };

  if (fileToUpload && fileToUpload.file) {
    try {
      if (shouldUploadFile(fileToUpload)) {
        await processFile(fileToUpload);
      }
    } catch (error) {
      handleError(error);
    }
  }

  deleteFiles();
};

__expose({
  uploadFiles,
});

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      ref_key: "fileInputRef",
      ref: fileInputRef,
      accept: ".jpg, .jpeg, .png",
      class: "upload__input",
      type: "file",
      onChange: onFileSelect
    }, null, 544),
    (!loadFile.value)
      ? _withDirectives((_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "upload-document-button",
          disabled: _unref(isFileBeingCropped),
          onClick: openFileInput
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "icon-container" }, [
            _createElementVNode("i", {
              class: "icon-plus_square",
              "aria-hidden": "true"
            })
          ], -1),
          _createElementVNode("div", { class: "text-container" }, [
            _createElementVNode("span", null, _toDisplayString('Añadir imagen de perfil')),
            _createElementVNode("span", null, _toDisplayString('Formatos válidos .jpeg, .png'))
          ], -1)
        ]), 8, _hoisted_2)), [
          [
            _directive_tooltip,
            _ctx.$t('common.attachFile'),
            void 0,
            { bottom: true }
          ]
        ])
      : _createCommentVNode("", true),
    (loadFile.value != undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (previewUrl.value)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: previewUrl.value,
                  alt: "Preview",
                  class: "preview-image"
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(loadFile.value.name), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(loadFile.value.file ? bytesToMB(loadFile.value.file.size) : ''), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives((_openBlock(), _createBlock(_component_Button, {
              class: "p-button-rounded p-button-only-icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (deleteLoadedImage()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: "icon-off_outline_close",
                  "aria-label": _ctx.$t('common.delete'),
                  "aria-hidden": "true"
                }, null, 8, _hoisted_10)
              ]),
              _: 1
            })), [
              [
                _directive_tooltip,
                _ctx.$t('common.delete'),
                void 0,
                { bottom: true }
              ]
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})